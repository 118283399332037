body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}
* {
  box-sizing: border-box;
}
.alice-carousel__dots-item {
  cursor: pointer;
}
a {
  text-decoration: none;
  display: block;
}
input {
  /* background-color: transparent; */
  /* width: 100%; */
}
input:focus {
  outline: none;
}
ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

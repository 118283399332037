
@font-face {
    font-display: swap;
    font-style: normal;
    src: url("/public/fonts/Poppins-Regular.ttf");
    font-family: "Poppins";
    font-weight: 400;
  }
  @font-face {
    font-display: swap;
    font-style: normal;
    src: url("/public/fonts/Poppins-Medium.ttf");
    font-family: "Poppins";
    font-weight: 500;
  }
  @font-face {
    font-display: swap;
    font-style: normal;
    src: url("/public/fonts/Poppins-SemiBold.ttf");
    font-family: "Poppins";
    font-weight: 600;
  }
  